import React from 'react';
import Iframe from 'react-iframe';

function App() {
  return (
    <Iframe
      url='https://pency.app/pcfoxcba'
      position='absolute'
      width='100%'
      id='myId'
      className='myClassname'
      height='100%'
      styles={{ height: '25px' }}
    />
  );
}

export default App;
